<template>
  <div class="finished">
    <div class="card">
      <div class="card-header">
        <b>제출 완료</b>
        <div>신청서 제출을 완료하였습니다.</div>
        <div class="action pt-3" v-if="state.archive.constraintFlag === 'Y'">
          <router-link :to="`/archive/categories/${state.archive.categoryId}`" class="font-sm pointer-u color-anchor">
            <span>다른 유형 신청하러 가기</span>
          </router-link>
        </div>
        <div class="sub" v-else-if="state.loaded">
          <span>영업일 기준 7일 이내로 심사 결과를 안내해드리겠습니다.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import ApplicationItem from "@/pages/archive/ApplicationItem";
import Anchor from "@/components/Anchor";
import http from "@/scripts/http";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageArchiveHome";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, ApplicationItem},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      http.get(`/api/archives/${router.app.$route.params.name}`).then(({data}) => {
        state.loaded = true;
        state.archive = data.body;
      });
    });

    const state = reactive({
      loaded: false,
      archive: {
        categoryId: 0,
        constraintFlag: "N"
      }
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.finished {
  .card {
    border: 0;
    background: none;

    .card-header {
      background: none;
      padding: $px220 $px30;
      border-bottom: 0;
      height: $px500;
      text-align: center;

      b {
        font-size: $px20;
        font-weight: 900;
      }

      div {
        padding-top: $px5;
        font-size: $px16;
        color: #a1a1a1;

        &.sub {
          font-size: $px12;
        }
      }

      .btn {
        margin-top: $px20;
        padding: $px16 $px24;
      }
    }
  }
}
</style>